#contact-container{
    background: #fff;
    display: flex;
    height: 650px;
    font-family: sans-serif;
    
}
.contact-info{
        background-color: #00AB55;
        flex: 0 1 40%;
        width: 40%;
        color: white;
        overflow: hidden;
        padding: 40px;
        flex-direction: column;

}
h4{
    font-size: 1.5rem;
    margin-top: 0%;
    
}
p{
    margin-bottom: 3rem;
}
.icon{
    font-size: 2.2rem;
}
span{
    padding-left: 10px;
    font-size: 1.1rem;

}
.icon-text{
    display: flex;
    
}
.icons{
    width: 10%;
    flex: 0 1 10%;
    flex-direction: column;
}
.social-media{
    display: flex;
    width: 80%;
    
    justify-content: space-between;
}
.icon-circle{
    flex: 0 1 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius:70%;
    background-color: #fff;
    transition: .5s;
}
.icon-circle:hover{
    background-color: #00AB55;
}
.form-contactUs{
    padding: 60px 100px 50px 100px;
    width: 100%;
    flex: 0 0 100%;
    color: rgb(70, 70, 70);
    
}
.form-group{
    display: flex;
    margin-bottom: 130px;
    flex: 0 1 100%;
    flex-direction: column;
    font-size: 1.2rem;
    justify-content: center;
    
}
.forms-input{
    padding-bottom: 20px;
}
a{
    text-decoration: none;
}